import React, { Component } from 'react';
import { Menu, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {getSession, setSession} from "../../../util/sessionUtil";
import request from "../../../util/request";

class WkMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            background: getSession('background'),
            textColor: getSession('textColor'),
            defaultLanguage: 'en',
            routeList: [
                {
                    menuId: 1,
                    parentId: 0,
                    name: '首页',
                    exact: true, // 是否父级菜单
                    enName: 'Home',
                    path: '/',
                    params: '',
                    component: 'Home',
                    menuType: 201,
                    children: []
                },
                {
                    menuId: 2,
                    parentId: 0,
                    exact: true,
                    name: '产品',
                    enName: 'Product',
                    path: '/product',
                    params: '',
                    component: 'Product',
                    menuType: 201,
                    children: [
                        {
                            menuId: 3,
                            parentId: 2,
                            exact: false,
                            name: '产品详情',
                            enName: 'ProductDetail',
                            path: '/product/detail/:id',
                            params: '',
                            menuType: 202,
                            component: 'productDetail',
                            children: []
                        }
                    ]
                },
                {
                    menuId: 4,
                    parentId: 0,
                    exact: true,
                    name: '关于公司',
                    enName: 'about',
                    path: '/about',
                    menuType: 201,
                    params: '',
                    component: 'About',
                    children: []
                },
                {
                    menuId: 5,
                    parentId: 0,
                    exact: true,
                    name: '公司新闻',
                    enName: 'news',
                    menuType: 201,
                    path: '/news',
                    params: '',
                    component: 'News',
                    children: [
                        {
                            menuId: 6,
                            parentId: 5,
                            name: '新闻详情',
                            exact: false,
                            menuType: 202,
                            enName: 'newsDetail',
                            path: '/news/detail/:id',
                            params: '',
                            component: 'NewsDetail',
                            children: []
                        }
                    ]
                }
            ],
            current: '/',
            websiteInfo: {
                name: '',
                doubleLang: 0
            },
            langOptions: [],
            isEnglish: true
        };
    }

    initWebsiteInfo() {
        request({
            url: '/dubai/query/website',
            method: 'get'
        }).then(res=>{
            if (res.code === 200) {
                this.setState({
                    websiteInfo: res.data
                })
                document.title = res.data.enName
                if (res.data.doubleLang === 0){
                    this.setState({
                        langOptions: [
                            { value: 'en', label: 'English' },
                            { value: 'zh', label: '简体中文' }
                            ]
                    })
                } else {
                    this.setState({
                        langOptions: [
                            { value: 'en', label: 'English' }
                        ]
                    })
                }
            }
        })
    }

    initMenuList(){
        request({
            url: '/dubai/query/menu',
            method: 'get'
        }).then(res=>{
            console.log('初始化menu',res)
            if (res.code === 200) {
                this.setState({
                    routeList: res.data
                })
            }
        })
    }

    componentDidMount() {
        const name = this.getFirstPath(this.props.location.pathname)
        this.setCurrent({ key: name });
        setSession('language', this.state.defaultLanguage)
        this.initWebsiteInfo()
        const { i18n } = this.props;
        i18n.changeLanguage('en');
        this.setState({
            isEnglish: getSession('language') === 'en'
        })

        this.initMenuList()
    }

    componentDidUpdate(prevProps) {
        /*截取第一个/和第二个/*/
        const name = this.getFirstPath(this.props.location.pathname)
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setCurrent({ key: name });
        }
        window.scrollTo(0, 0)
    }

    getFirstPath(path) {
        const name = path.split('/')[1]
        return "/"+name
    }

    setCurrent(e) {
        this.setState({
            current: e.key,
        });
    }

    handleChange = (value) => {
        const { i18n } = this.props;
        i18n.changeLanguage(value);
        setSession('language', value);
        window.location.reload()
    };

    onClick = (e) => {
        if (e.key === 'lang') return;
        this.setCurrent(e);
        this.props.history.push(e.key);
    };

    render() {
        const { t } = this.props;
        const { current, defaultLanguage,routeList,isEnglish,background,textColor } = this.state;

        return (
            <div>
                <Menu
                    style={{
                        backgroundColor: background,
                        zIndex: '2',
                    }}
                    onClick={this.onClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                >
                    {
                        routeList.map((item, index) => {
                          return (
                                item.children.length < 1 ?
                                    (
                                        <Menu.Item key={item.path}
                                            style={{
                                                color: textColor
                                            }}
                                        >
                                            {!isEnglish?item.name:item.enName}
                                        </Menu.Item>
                                    )
                                    :
                                    (
                                        <Menu.SubMenu onTitleClick={this.onClick} key={item.path} title={!isEnglish?item.name:item.enName}>
                                            {
                                                item.children.map((child, index) => {
                                                    return (
                                                        child.menuType !== 201 ?
                                                            <div/> : (
                                                                <Menu.Item
                                                                    style={{
                                                                        color: textColor
                                                                    }}
                                                                    key={child.path}>
                                                                    {!isEnglish?child.enName:child.enName}
                                                                </Menu.Item>
                                                            )
                                                    )
                                                })
                                            }
                                        </Menu.SubMenu>
                                    )

                          )
                        })
                    }
                    {/*<Menu.Item key="/">
                        {t('menu.home')}
                    </Menu.Item>
                    <Menu.Item key="/product">
                        {t('menu.product')}
                    </Menu.Item>
                    <Menu.Item key="/news">
                        {t('menu.news')}
                    </Menu.Item>
                    <Menu.Item key="/about">
                        {t('menu.about')}
                    </Menu.Item>*/}
                    {
                        this.state.langOptions.length > 1 ?
                            <Menu.Item key="lang"
                                       style={{
                                           color: textColor
                                       }}
                            >
                                <Select
                                    defaultValue={defaultLanguage}
                                    style={{ width: '120px', background: background, color: textColor }}
                                    bordered={false}
                                    onChange={this.handleChange}
                                    options={this.state.langOptions}
                                />
                            </Menu.Item>
                            : null
                    }
                </Menu>
            </div>
        );
    }
}

export default withTranslation()(withRouter(WkMenu));
